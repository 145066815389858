import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Box, Heading, Link as ExternalLink } from 'theme-ui'
import ReactPageScroller from 'react-page-scroller'
import { FaMapPin, FaArrowAltCircleRight } from 'react-icons/fa'
import slugify from 'slugify'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

const Gallery = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageChange = (number) => setCurrentPage(number)

  const galleryData = data.allContentfulGallery.nodes

  useEffect(() => {
    disablePageScroll()
    return () => {
      enablePageScroll()
    }
  }, [])

  return (
    <Box sx={styles.container}>
      <ReactPageScroller
        pageOnChange={handlePageChange}
        customPageNumber={currentPage}
      >
        {galleryData.map((album) => {
          const backgroundImage = {
            backgroundImage: `url(${album.albumCover.file.url})`,
          }
          const lat = album.location.lat.toFixed(4)
          const lon = album.location.lon.toFixed(4)
          const galleryLink = slugify(album.albumName, { lower: true })
          return (
            <Box sx={{ ...styles.galleryContainer, ...backgroundImage }}>
              <Box sx={styles.textContainer}>
                <Heading as='h2' sx={styles.albumName}>
                  {album.albumName}
                </Heading>
                <Heading as='h4' sx={styles.albumSubtitle}>
                  {album.albumSubtitle}
                </Heading>
                <Box sx={styles.galleryLinkButton}>
                  <AniLink
                    to={`/gallery/${galleryLink}`}
                    rel='noopener'
                    paintDrip
                    duration={0}
                    hex='#e01f5a'
                  >
                    View Guide <FaArrowAltCircleRight />
                  </AniLink>
                </Box>
              </Box>

              <Heading as='h6' sx={styles.coordinates}>
                <ExternalLink
                  href={`https://www.google.com/maps/search/${lat},${lon}/@${lat},${lon}`}
                  target='_blank'
                  rel='noopener'
                >
                  <FaMapPin /> Lat: {lat}, Long: {lon}
                </ExternalLink>
              </Heading>
            </Box>
          )
        })}
      </ReactPageScroller>
    </Box>
  )
}

export const query = graphql`
  query GalleryQuery {
    allContentfulGallery(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        albumCover {
          file {
            url
          }
          description
        }
        albumName
        albumSubtitle
        description {
          childrenMarkdownRemark {
            html
          }
        }
        images {
          file {
            url
          }
          description
        }
        location {
          lat
          lon
        }
        id
      }
    }
  }
`

const styles = {
  container: {
    width: '100%',
    minHeight: ['calc(100vh - 60.17px)', '', 'calc(100vh - 86px)'],
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  innerContainer: {
    margin: 'auto 0rem 0rem',
    height: ['calc(100vh - 60.17px)', '', 'calc(100vh - 86px)'],
  },
  galleryContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100vh',
    position: 'relative',
    '::after': {
      content: "''",
      height: '100%',
      width: '100%',
      position: 'absolute',
      left: '0rem',
      top: '0rem',
      background:
        'linear-gradient(193deg, rgba(46,59,73,0) 0%, rgba(49,55,62,0) 64%, rgba(0,0,0,1) 100%)',
    },
  },
  textContainer: {
    position: 'absolute',
    bottom: ['15%', '', '2rem'],
    left: '2rem',
    color: 'white',
    zIndex: '1',
  },
  albumName: {
    fontSize: ['2rem', '2.5rem', '3.5rem', '5rem'],
  },
  albumSubtitle: {
    fontSize: ['1.2rem', '1.4rem', '2rem', '3rem'],
    color: 'secondary',
  },
  coordinates: {
    color: 'white',
    fontSize: '0.9rem',
    position: 'absolute',
    right: '0px',
    padding: '0.5rem',
    backgroundColor: 'rgba(0,0,0,0.8)',
    top: ['100px', '', '30%'],
    writingMode: 'tb',
    color: 'secondary',
    zIndex: '1',
  },
  galleryLinkButton: {
    zIndex: '2',
    // position: 'absolute',
    right: '0rem',
    bottom: '0rem',
    // backgroundColor: 'secondary',
    a: {
      display: 'flex',
      padding: '1rem 0rem 0rem',
      alignItems: 'center',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.5rem'],
      letterSpacing: '1px',

      color: 'white',
      svg: {
        margin: '0rem 0rem 0rem 0.5rem',
        fontSize: '1.5rem',
      },
    },
  },
}

export default Gallery
